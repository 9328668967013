import httpManager from "../axios/httpManager";

export function getLeisureAttrList () {
    let url = '/leisuerconfig/attr_list?t=' + new Date().getTime();
    return httpManager.get(url);
}
export function getLeisureAttrById (idx) {
    return httpManager.get('/leisuerconfig/attr_get_by_id?idx=' + idx + '&t=' + new Date().getTime())
}
export function updateLeisureAttr (leisuerAttribute) {
    return httpManager.post('/leisuerconfig/attr_update?t=' + new Date().getTime(), leisuerAttribute);
}


export function getLeisureKindList () {
    let url = '/leisuerconfig/kind_list?t=' + new Date().getTime();
    return httpManager.get(url);
}
export function getLeisureKindById (idx) {
    return httpManager.get('/leisuerconfig/kind_get_by_id?idx=' + idx + '&t=' + new Date().getTime())
}
export function updateLeisureKind (leisureKind) {
    return httpManager.post('/leisuerconfig/kind_update?t=' + new Date().getTime(), leisureKind);
}