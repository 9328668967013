import httpManager from "../axios/httpManager";

export function getTeamNameMatchingList (search, pageNum, pageSize) {
    let url = '/teamnamematching/list'+'?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}

export function getTeamNameMatchingById (id) {
    return httpManager.get('/teamnamematching/get_by_id?id=' + id + '&t=' + new Date().getTime())
}

export function deleteTeamNameMatching (id) {
    return httpManager.get('/teamnamematching/delete?id=' + id + '&t=' + new Date().getTime())
}
export function updateTeamNameMatching (update) {
    return httpManager.post('/teamnamematching/update', update);
}
export function saveTeamNameMatching (insert) {
    return httpManager.post('/teamnamematching/save', insert);
}
export function getPlatForm () {
    return httpManager.get('/get_platform?t=' + new Date().getTime());
}