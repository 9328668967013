<template>
    <div class="main">
        <div class="search">
            <el-select v-model="teamName.byField" size="mini" placeholder="검색조건 선택"
                       style="width:130px;margin-left: 5px">
                <el-option key="2" label="관리자 팀명" value="teamname"></el-option>
                <el-option key="1" label="경기서버 팀명" value="matchingname"></el-option>
            </el-select>
            <el-input size="mini" style="width: 300px;margin-left: 5px" v-model="teamName.searchString"
                      placeholder="검색내용"></el-input>
            <el-select size="mini" style="width: 150px;margin-left: 5px" v-model="teamName.gameTypeId" placeholder="종목별">
                <el-option :value="null" label="전체">전체</el-option>
                <el-option v-for="item in gameTypeList" :value="item.id" :label="item.typeName">{{item.typeName}}</el-option>
            </el-select>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="getTeanNameList">검색</el-button>
        </div>

        <div class="data" style="overflow-y: scroll;max-height: 850px;background-color: #fff9ff">
            <el-table
                    :data="teamNameList"
                    style="width: 100%"
                    max-height="750"
                    border>
                <el-table-column
                        fixed
                        label="관리자 팀명"
                        width="300">
                    <template slot-scope="scope">
                        <el-input style="width: 80%" size="mini" v-model="scope.row.teamName"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="경기서버 팀명"
                        width="300">
                    <template slot-scope="scope">
                        <span style="color: #ff00ff">{{scope.row.matchingName}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="종목"
                        width="170">
                    <template slot-scope="scope">
                        <el-select size="mini" style="width: 130px;margin-left: 5px" v-model="scope.row.gameTypeId" placeholder="종목">
                            <el-option v-for="item in gameTypeList" :value="item.id" :label="item.typeName">{{item.typeName}}</el-option>
                        </el-select>
                    </template>
                </el-table-column>



                <el-table-column
                        fixed
                        label="action"
                        width="120">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="updateTeamName(scope.row)">수정하기</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20,50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {Loading} from "element-ui";
    import {getHonorCasinoBetlist, sysData} from "../../network/manager/honorCasinoRequest";
    import {manager} from "../../common/administrator/managerMixin";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import Vue from "vue";
    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    import {getRegisterCodeList, updateRegisterCode} from "../../network/manager/registerCodeRequest";
    import managerConst from "../../common/administrator/managerConst";
    import {getRankList} from "../../network/manager/rankRequest";
    import {getGroupList} from "../../network/manager/groupRequest";
    import PartnerEditToast from "../../components/administrator/UserPanel/PartnerEditToast";
    import {
        getLeisureAttrList,
        getLeisureKindList, updateLeisureAttr,
        updateLeisureKind
    } from "../../network/manager/leisureConfigRequest";
    import {getTeamNameMatchingList, updateTeamNameMatching} from "../../network/manager/teamNameMachingRequest";
    import {getGameTypeList} from "../../network/manager/gameTypeRequest";

    export default {
        name: "ManagerTeamNameMatching",
        mixins: [manager],
        components: {
            DateSelectorComp,
            ManagerTopbarComp, TodayStatisticComp, OnlineUserComp
        },
        data() {
            return {
                startDate: '',
                endDate: '',
                teamName: {},
                teamNameList: [],
                pageNum: 1,
                pageSize: 20,
                pageTotal: 0,
                gameTypeList:[],
            }
        },
        methods: {
            getTeanNameList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getTeamNameMatchingList(this.teamName,this.pageNum,this.pageSize).then(res => {
                    this.teamNameList = res.data.data
                    this.pageTotal = res.data.total
                    loadingInstance.close()
                })
            },
            updateTeamName(item) {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                item.matchingName = null
                updateTeamNameMatching(item).then(res => {
                    loadingInstance.close()
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '업데이트 완료되였습니다'
                        });
                        this.getTeanNameList()
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 3000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
            },

            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getTeanNameList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getTeanNameList();
            },

        },
        created() {
            getGameTypeList().then(res => {
                this.gameTypeList = res.data.data;
            })
            this.getTeanNameList()
        },
    }
</script>

<style scoped>

</style>