import httpManager from "../axios/httpManager";

export function getGameTypeList() {
    return httpManager.get('/gametype/list?t=' + new Date().getTime())
}
export function save(record) {
    return httpManager.post('/gametype/save',record)
}
export function getById(id) {
    return httpManager.get('/gametype/get_by_id?id='+id + '&t=' + new Date().getTime())
}
export function updateGameType(record) {
    return httpManager.post('/gametype/update',record)
}
